<div class="pagination">
    <div class="status">
        {{(pageNumber-1)*pageSize+1}} - {{totalItems < pageSize ? totalItems : (pageNumber)*pageSize}} of {{totalItems}}
    </div>
    <div class="page-number">
        <div class="btn" [class.disabled]="pageNumber == 1" (click)="gotoPage(pageNumber-1)">❮</div>

        @for (item of tempPagesArray; track $index) {
        @if (item != '...') {
        <div class="btn page" [class.active]="item == pageNumber" (click)="gotoPage(item)">
            {{item}}
        </div>
        }
        @else {
        <div class="btn page">
            ...
        </div>
        }
        }


        <div class="btn" [class.disabled]="pageNumber == totalPages" (click)="gotoPage(pageNumber+1)">❯
        </div>
    </div>

    <!-- <div class="page-size">
        <select (change)="pageSizeChanged()" [(ngModel)]="pageSize">
            <option value="10">{{10}}</option>
            <option value="25">{{25}}</option>
            <option value="50">{{50}}</option>
            <option value="100">{{100}}</option>
        </select>
        عرض
    </div> -->
</div>