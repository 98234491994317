<table class="table text-right" [class.nodata]="tableData?.length == 0">
    <thead>
        <tr>
            <th (click)="setOrder(head)" *ngFor="let head of tableHeaders; let i = index">
                {{ head.name }}
                @if (head.sort) {
                <img src="./../../../../assets/sort.svg" alt="">
                }

            </th>
            <!-- <th class="text-center">
                <span *ngIf="showDeleteCoulmnName">
                    حذف
                </span>
            </th> -->
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let element of tableData; let in = index " (click)="viewElement(element)">
            <td *ngFor="let head of tableHeaders" (click)="rowClicked(element, head.clickable);$event.preventDefault()"
                [class.clickable]="head.clickable">
                @if (head.type == 'string' && (head.editType == null || !element.editFlag)) {
                {{element[head.key]}}
                }
                @if (head.type == 'number' && (head.editType == null || !element.editFlag)) {
                {{element[head.key] | number}}
                }
                @if (head.type == 'date' && (head.editType == null || !element.editFlag)) {
                {{element[head.key]?.substring(0,10)}}

                }
                @if (head.type == 'time' && (head.editType == null || !element.editFlag)) {
                {{element[head.key]?.substring(11)}}

                }
                @if (head.type == 'tag') {
                <div class="tag"
                    [ngStyle]="{'background-color': getBackgroundColor(element, head), 'color': getColor(element, head)}">
                    <div class="dot" [ngStyle]="{'background-color': getColor(element, head)}"></div>
                    {{element[head.key]}}
                </div><br>
                }


            </td>
            <!-- <td class="text-center">
                <img *ngIf="tableConfig.allowDelete" src="./../../../../assets/delete.svg" alt=""
                    (click)="deleteElement(element, in);$event.stopPropagation()" class="delete-icon">
                <img *ngIf="tableConfig.allowEdit && !element.editFlag" src="./../../../../assets/edit.svg" alt=""
                    (click)="editElement(element, in);$event.stopPropagation()" class="edit-icon">
                <img *ngIf="tableConfig.allowEdit && element.editFlag" src="./../../../../assets/save.svg" alt=""
                    (click)="saveElement(element, in);$event.stopPropagation()" class="save-icon">
            </td> -->
        </tr>

    </tbody>

</table>