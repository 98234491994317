import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Header, TableConfig } from '../../models/TableConfig.model';

@Component({
  selector: 'app-data-table',
  standalone: false,
  templateUrl: './data-table.component.html',
  styleUrl: './data-table.component.scss'
})
export class DataTableComponent {
  @Input() tableConfig!: TableConfig;

  @Output() viewEvent = new EventEmitter();
  @Output() sortEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter();
  @Output() clickEvent = new EventEmitter();


  tableHeaders: any[] = [];
  tableData: any = [];

  sortAscOrder = true;
  sortKey = "";

  showDeleteCoulmnName: boolean = false;
  instantEdit: boolean | undefined = true;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.tableHeaders = this.tableConfig?.headers;
    this.tableData = this.tableConfig?.data;
    this.showDeleteCoulmnName = this.tableConfig?.showDeleteCoulmnName;
    this.instantEdit = this.tableConfig?.instantEdit;
  }

  setOrder(head: any) {
    if (head.sort) {
      const ascending = this.tableData.sort((a: any, b: any) => (a[head.key] > b[head.key] ? 1 : -1));
      this.sortAscOrder = head.key == this.sortKey ? !this.sortAscOrder : true;
      this.sortKey = head.key;
      this.tableData = this.sortAscOrder ? ascending : ascending.reverse();

      this.sortEvent.emit({ key: this.sortKey, order: this.sortAscOrder })
    }
  }
  deleteElement(element: any, index: number) {
    element.index = index;
    this.deleteEvent.emit(element);
  }
  editElement(element: any, index: number) {
    if (this.instantEdit == true || this.instantEdit == undefined) {
      element.editFlag = true;
    }
    element.index = index;
    this.editEvent.emit(element);
  }
  saveElement(element: any, index: number) {
    element.index = index;
    this.saveEvent.emit(element);
  }

  viewElement(element: any) {
    this.viewEvent.emit(element.id);
  }

  rowClicked(element: any, clickable: boolean = false) {
    if (clickable) {
      this.clickEvent.emit(element);
    }
  }

  getBackgroundColor(element: any, head: Header) {
    const clazz = head.classes?.find((cls: any) => cls.name == element[head.key]);

    return clazz ? clazz.background : null;
  }
  getColor(element: any, head: Header) {
    const clazz = head.classes?.find((cls: any) => cls.name == element[head.key]);
    return clazz ? clazz.color : null;
  }

}
