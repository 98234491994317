import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DataTableComponent } from './components/data-table/data-table.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { MsgPopupComponent } from './components/msg-popup/msg-popup.component';





@NgModule({
  declarations: [
    DataTableComponent,
    PaginationComponent,
    MsgPopupComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    FormsModule,
    DataTableComponent,
    PaginationComponent,
    MsgPopupComponent
  ]
})
export class SharedModule { }
